.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.my-container {
  /*  订单*/
}
.my-container .head-box {
  position: relative;
  height: 2.4rem;
  background: #fff;
  padding: 0.42rem 0.24rem 0.42rem 0.42rem;
  font-size: 0.38rem;
  margin-bottom: 0.2rem;
}
.my-container .head-box .head-img {
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
  margin-right: 0.3rem;
}
.my-container .head-box .info-box {
  padding: 0.22rem 0;
  font-weight: 500;
  white-space: nowrap;
}
.my-container .head-box .info-box .integral-box {
  font-size: 0.24rem;
  color: #333;
  background: url("../../assets/icon_detail@3x.png") no-repeat right bottom;
  background-size: 0.32rem 0.32rem;
  padding-right: 0.32rem;
  white-space: nowrap;
}
.my-container .head-box .info-box .integral-number {
  font-size: 0.44rem;
  color: #333;
  margin-right: 0.06rem;
  font-weight: bold;
}
.my-container .head-box .detail_box {
  width: 20%;
  height: 100%;
}
.my-container .head-box .detail_box .detail_icon {
  width: 0.32rem;
  height: 0.32rem;
  background-image: url('../../assets/icon_detail@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .my-container .head-box .detail_box .detail_icon {
    background-image: url('../../assets/icon_detail@3x.png');
  }
}
.my-container .tab-list {
  background: #fff;
  height: 2.64rem;
}
.my-container .tab-list .point {
  width: 100%;
  height: 0.88rem;
  font-size: 0.32rem;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-container .tab-list .point .point-detail {
  width: calc(100% - 0.48rem);
  height: 0.88rem;
  padding-right: 0.3rem;
  font-size: 100%;
  font-weight: normal;
  color: #333;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
}
.my-container .tab-list .point .point-detail .txt {
  line-height: 0.88rem;
}
.my-container .tab-list .point .point-detail .points {
  padding-right: 0.4rem;
}
.my-container .tab-list .point .points {
  padding-right: 0.3rem;
}
.my-container .tab-list .tab-item {
  width: 100%;
  height: 0.88rem;
  font-size: 0.32rem;
  color: #333333;
  background: white url(../../assets/icon_detail@3x.png) no-repeat 97% 50%;
  background-size: 0.4rem 0.4rem;
}
.my-container .tab-list .tab-item img {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 0.2rem 0 0.3rem;
}
.my-container .tab-list .tab-item .right-tab {
  width: 100%;
  height: 100%;
  line-height: 0.88rem;
}
.my-container .tab-list .tab-item .right-tab span {
  color: #999;
  margin-left: 0.32rem;
}
.my-container .tab-list .my-point {
  background: none;
}
.my-container .order-box {
  height: 2.44rem;
  background: #fff;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}
.my-container .order-box .order-title {
  height: 0.68rem;
  padding-right: 0.46rem;
  padding-left: 0.24rem;
  background: white url(../../assets/icon_more@3x.png) no-repeat 97% 50%;
  background-size: 0.14rem 0.24rem;
}
.my-container .order-box .order-title .left {
  font-size: 0.32rem;
  color: #2b2b2b;
}
.my-container .order-box .order-title .right {
  font-size: 0.24rem;
  color: #999999;
}
.my-container .order-box .order-type {
  height: 1.76rem;
  padding: 0.44rem 0.3rem 0.24rem 0.38rem;
  font-size: 0.28rem;
  color: #999999;
}
.my-container .order-box .order-type .order-item {
  height: 100%;
  position: relative;
}
.my-container .order-box .order-type .order-item i {
  font-size: 0.48rem;
  color: var(--main-color);
}
.my-container .order-box .order-type .order-item .mark {
  position: absolute;
  background: #ec5b55;
  border-radius: 50%;
  width: 0.28rem;
  height: 0.28rem;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.28rem;
  text-align: center;
  right: 0;
  top: -0.28rem;
}
