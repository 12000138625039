.border-none[data-v-761a3957]:before {
  display: none;
}
.border-none[data-v-761a3957]:after {
  display: none;
}
.flex-fix[data-v-761a3957] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-761a3957]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-761a3957] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-761a3957] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-761a3957] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-761a3957] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-761a3957] {
  *zoom: 1;
}
.clear-fix[data-v-761a3957]:before,
.clear-fix[data-v-761a3957]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.my-container[data-v-761a3957] {
  /*  订单*/
}
.my-container .head-box[data-v-761a3957] {
  position: relative;
  height: 2.4rem;
  background: #fff;
  padding: 0.42rem 0.24rem 0.42rem 0.42rem;
  font-size: 0.38rem;
  margin-bottom: 0.2rem;
}
.my-container .head-box .head-img[data-v-761a3957] {
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
  margin-right: 0.3rem;
}
.my-container .head-box .info-box[data-v-761a3957] {
  padding: 0.22rem 0;
  font-weight: 500;
  white-space: nowrap;
}
.my-container .head-box .info-box .integral-box[data-v-761a3957] {
  font-size: 0.24rem;
  color: #333;
  background: url("../../assets/icon_detail@3x.png") no-repeat right bottom;
  background-size: 0.32rem 0.32rem;
  padding-right: 0.32rem;
  white-space: nowrap;
}
.my-container .head-box .info-box .integral-number[data-v-761a3957] {
  font-size: 0.44rem;
  color: #333;
  margin-right: 0.06rem;
  font-weight: bold;
}
.my-container .head-box .detail_box[data-v-761a3957] {
  width: 20%;
  height: 100%;
}
.my-container .head-box .detail_box .detail_icon[data-v-761a3957] {
  width: 0.32rem;
  height: 0.32rem;
  background-image: url('../../assets/icon_detail@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.my-container .head-box .detail_box .detail_icon[data-v-761a3957] {
    background-image: url('../../assets/icon_detail@3x.png');
}
}
.my-container .tab-list[data-v-761a3957] {
  background: #fff;
  height: 2.64rem;
}
.my-container .tab-list .point[data-v-761a3957] {
  width: 100%;
  height: 0.88rem;
  font-size: 0.32rem;
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-container .tab-list .point .point-detail[data-v-761a3957] {
  width: calc(100% - 0.48rem);
  height: 0.88rem;
  padding-right: 0.3rem;
  font-size: 100%;
  font-weight: normal;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
}
.my-container .tab-list .point .point-detail .txt[data-v-761a3957] {
  line-height: 0.88rem;
}
.my-container .tab-list .point .point-detail .points[data-v-761a3957] {
  padding-right: 0.4rem;
}
.my-container .tab-list .point .points[data-v-761a3957] {
  padding-right: 0.3rem;
}
.my-container .tab-list .tab-item[data-v-761a3957] {
  width: 100%;
  height: 0.88rem;
  font-size: 0.32rem;
  color: #333333;
  background: white url(../../assets/icon_detail@3x.png) no-repeat 97% 50%;
  background-size: 0.4rem 0.4rem;
}
.my-container .tab-list .tab-item img[data-v-761a3957] {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 0.2rem 0 0.3rem;
}
.my-container .tab-list .tab-item .right-tab[data-v-761a3957] {
  width: 100%;
  height: 100%;
  line-height: 0.88rem;
}
.my-container .tab-list .tab-item .right-tab span[data-v-761a3957] {
  color: #999;
  margin-left: 0.32rem;
}
.my-container .tab-list .my-point[data-v-761a3957] {
  background: none;
}
.my-container .order-box[data-v-761a3957] {
  height: 2.44rem;
  background: #fff;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}
.my-container .order-box .order-title[data-v-761a3957] {
  height: 0.68rem;
  padding-right: 0.46rem;
  padding-left: 0.24rem;
  background: white url(../../assets/icon_more@3x.png) no-repeat 97% 50%;
  background-size: 0.14rem 0.24rem;
}
.my-container .order-box .order-title .left[data-v-761a3957] {
  font-size: 0.32rem;
  color: #2b2b2b;
}
.my-container .order-box .order-title .right[data-v-761a3957] {
  font-size: 0.24rem;
  color: #999999;
}
.my-container .order-box .order-type[data-v-761a3957] {
  height: 1.76rem;
  padding: 0.44rem 0.3rem 0.24rem 0.38rem;
  font-size: 0.28rem;
  color: #999999;
}
.my-container .order-box .order-type .order-item[data-v-761a3957] {
  height: 100%;
  position: relative;
}
.my-container .order-box .order-type .order-item i[data-v-761a3957] {
  font-size: 0.48rem;
  color: var(--main-color);
}
.my-container .order-box .order-type .order-item .mark[data-v-761a3957] {
  position: absolute;
  background: #ec5b55;
  border-radius: 50%;
  width: 0.28rem;
  height: 0.28rem;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.28rem;
  text-align: center;
  right: 0;
  top: -0.28rem;
}
